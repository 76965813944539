<template>
  <div>
    <ul class="tree-wrapper">
      <tree-node
        :item="data"
        :root="true"
        @make-folder="makeFolder"
        @add-item="addItem"
        @update-item="updateItem"
        @delete-item="deleteItem"
      ></tree-node>
    </ul>
    <modal
      :show="showModal"
      @cancel="showModal = false"
      @submit="createOrUpdateCategory"
      :submit-button-text="modelSubmitButtonText"
      focus-element="new-category-title"
    >
      <span slot="header">{{ modalTitle }}</span>
      <template slot="body">
        <form class="py-3" autocomplete="off" @submit.prevent="createOrUpdateCategory">
          <div class="mb-3">
            <label for="new-category-title" class="form-label">Название</label>
            <input
              type="text"
              class="form-control"
              id="new-category-title"
              placeholder="Название категории..."
              v-model="categoryTitle">
          </div>
        </form>
      </template>
    </modal>
  </div>
</template>

<script>
import TreeNode from './TreeNode'
import Vue from 'vue'
import Modal from '@/components/common/Modal'

export default {
  data () {
    return {
      showModal: false,
      modalMode: 'new',
      categoryTitle: '',
      categoryParent: null,
      item: null
    }
  },
  props: {
    data: Object
  },
  methods: {
    makeFolder (item) {
      Vue.set(item, 'children', [])
      this.addItem(item)
    },
    addItem (item) {
      this.modalMode = 'new'
      this.categoryTitle = ''
      this.categoryParent = item
      this.showModal = true
    },
    updateItem (item) {
      this.modalMode = 'update'
      this.categoryTitle = item.title
      this.showModal = true
      this.item = item
    },
    deleteItem (item) {
      this.$store.dispatch('categories/delete', item.id)
        .then((response) => {
          const countDeleted = response.data.deleted.length
          const message = countDeleted === 1
            ? `Категория <b>${item.title}</b> удалена`
            : `Удалено ${countDeleted} категории`
          this.notifyCategoryDeleted({ message: message })
        })
    },
    createOrUpdateCategory () {
      if (!this.categoryTitle) return

      if (this.modalMode === 'new') {
        this.$store.dispatch('categories/create', {
          parent_id: this.categoryParent.id,
          title: this.categoryTitle
        })
          .then(response => {
            this.showModal = false
            this.notifyCategoryCreated({ message: `Категория <b>${this.categoryTitle}</b> создана` })
          })
      } else {
        this.$store.dispatch('categories/update', {
          id: this.item.id,
          title: this.categoryTitle
        })
          .then(response => {
            this.showModal = false
            this.notifyCategoryUpdated()
          })
      }
    }
  },
  computed: {
    modalTitle () {
      switch (this.modalMode) {
        case 'new':
          return 'Новая категория'
        case 'update':
          return 'Изменить категорию'
        default:
          return ''
      }
    },
    modelSubmitButtonText () {
      switch (this.modalMode) {
        case 'new':
          return 'Создать'
        case 'update':
          return 'Изменить'
        default:
          return 'Сохранить'
      }
    }
  },
  components: {
    TreeNode,
    Modal
  },
  notifications: {
    notifyCategoryDeleted: {
      type: 'success',
      message: 'Категория удалена',
      title: ''
    },
    notifyCategoryCreated: {
      type: 'success',
      message: 'Категория создана',
      title: ''
    },
    notifyCategoryUpdated: {
      type: 'success',
      message: 'Категория обновлена',
      title: ''
    }
  }
}
</script>

<style lang="scss" scoped>
.tree-wrapper {
  margin: 0;
  padding: 0;
}
</style>
