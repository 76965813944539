<template>
  <li class="tree-node" v-if="item">
    <div
      v-if="!root"
      :style="elStyle"
      class="d-flex"
    >
      <b-dropdown no-caret variant="link" :lazy="true">
        <template #button-content>
          <font-awesome-icon icon="ellipsis-h" class="mr-2 tree-dots"/>
        </template>
        <template class="p-0 m-0">
          <b-dropdown-item @click="$emit('add-item', item)">
            Добавить подкатегорию
          </b-dropdown-item>
          <template v-if="item.is_editable">
            <b-dropdown-divider></b-dropdown-divider>
            <b-dropdown-item @click="$emit('update-item', item)">
              Изменить категорию
            </b-dropdown-item>
          </template>
          <template v-if="item.is_editable">
            <b-dropdown-divider></b-dropdown-divider>
            <b-dropdown-item @click="$emit('delete-item', item)">
              Удалить категорию
            </b-dropdown-item>
          </template>
        </template>
      </b-dropdown>
      <span
        @click="toggle"
      >
        <font-awesome-icon
          :style="{color: hasChildren ? '#eee' : '#fff', width: '16px', height: '16px'}"
          :icon="isOpen ? 'angle-down' : 'angle-right'"
          class="mr-1 tree-arrow"
        />
        {{ item.title }}
      </span>
    </div>
    <ul class="tree-wrapper" v-show="isOpen || root">
      <tree-node
        v-for="(child, index) in item.children"
        :key="index"
        :item="child"
        :level="root ? 0 : level + 1"
        @make-folder="$emit('make-folder', $event)"
        @add-item="$emit('add-item', $event)"
        @update-item="$emit('update-item', $event)"
        @delete-item="$emit('delete-item', $event)"
      ></tree-node>
    </ul>
  </li>
</template>

<script>
export default {
  name: 'TreeNode',
  props: {
    item: Object,
    root: {
      type: Boolean,
      default: false
    },
    level: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      isOpen: true
    }
  },
  computed: {
    hasChildren () {
      return this.item.children && this.item.children.length
    },
    elStyle () {
      return {
        marginLeft: (this.level * 10) + 'px',
        fontWeight: (!this.item.is_editable) ? 'bold' : 'normal'
      }
    }
  },
  methods: {
    toggle () {
      if (this.hasChildren) {
        this.isOpen = !this.isOpen
      }
    },
    makeFolder () {
      if (!this.hasChildren) {
        this.$emit('make-folder', this.item)
        this.isOpen = true
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.tree-node {
  list-style: none;
  position: relative;

  &>div {
    cursor: pointer;
    transition: display 85ms ease;

    .tree-dots {
      color: #eee;
      // display: none;

      &:hover {
        color: #ccc;
      }
    }
  }
}
.tree-wrapper {
  margin: 0;
  padding: 0;
}
</style>

<style lang="scss">
.tree-node {
  .dropdown-toggle {
    padding: 0;
    margin: 0;
  }
  .dropdown-divider {
    margin: 0;
    border: none;
  }
  .dropdown-menu {
    padding-top: 4px;
    padding-bottom: 4px;
  }
}
</style>
