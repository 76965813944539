<template>
  <transition name="modal" v-if="show">
    <div class="m-modal-mask">
      <div class="m-modal-wrapper px-2">
        <div class="m-modal-container rounded">

          <div class="m-modal-header p-3 border-bottom rounded-top">
            <slot name="header">
            </slot>
          </div>

          <div class="m-modal-body px-3">
            <slot name="body">
            </slot>
          </div>

          <div class="m-modal-footer p-3 border-top rounded-bottom">
            <slot name="footer">
              <div class="d-flex justify-content-end">
                <button class="btn btn-light mr-2" @click="cancel">
                  {{ cancelButtonText }}
                </button>
                <button class="btn btn-success" @click="$emit('submit')">
                  {{ submitButtonText }}
                </button>
              </div>
            </slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'Modal',
  props: {
    submitButtonText: {
      type: String,
      default: 'Сохранить'
    },
    cancelButtonText: {
      type: String,
      default: 'Отменить'
    },
    show: {
      type: Boolean,
      default: false
    },
    focusElement: {
      type: String,
      default: null
    }
  },
  methods: {
    cancel () {
      this.$emit('cancel')
    }
  },
  updated () {
    if (this.show && this.focusElement) {
      setTimeout(() => {
        document.getElementById(this.focusElement).focus()
      }, 10)
    }
  }
}
</script>

<style lang="scss" scoped>
.m-modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 85ms ease;
}

.m-modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.m-modal-container {
  max-width: 400px;
  margin: 0px auto;
  background-color: #fff;
  transition: all 85ms ease;
}

.m-modal-header {
  background-color: #fafafa;
  font-weight: 700;
}

.m-modal-enter {
  opacity: 0;
}

.m-modal-leave-active {
  opacity: 0;
}

.m-modal-enter .m-modal-container,
.m-modal-leave-active .m-modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

</style>
