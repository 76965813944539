<template>
  <div class="container">
    <categories-tree :data="categories"/>
  </div>
</template>

<script lang="js">
import CategoriesTree from '@/components/views/categories/CategoriesTree'
import { mapGetters } from 'vuex'

export default {
  data () {
    return {}
  },
  computed: {
    ...mapGetters({
      categories: 'categories/all'
    })
  },
  mounted () {
    this.$store.dispatch('categories/fetch')
  },
  components: {
    CategoriesTree
  }
}
</script>
